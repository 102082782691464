export const getEnvironmentFromHost = (host: string) => {
  const firstSegment = host.split('.')[0];

  if (firstSegment.startsWith('localhost') || firstSegment.endsWith('dev') || firstSegment.startsWith('s3-proxy') || firstSegment.includes('eod')) {
    return 'development';
  }

  if (firstSegment.endsWith('staging')) {
    return 'staging';
  }

  return 'production';
};
